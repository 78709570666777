import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import type { Route } from "vue-router";
import { ReportJob, ReportJobCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<ReportJob, ReportJobCollection>
>;

@Component
export default class ReportJobMixin extends Mixins(TypedModelMixin) {
  obCollection = new ReportJobCollection();
  obModelClass = ReportJob;
  obCollectionClass = ReportJobCollection;
  sRoutePath = "reportjob.list";

  created() {
    this.onCreated();
  }
}
