<template>
  <sheet-filters
    v-model="obFilterData"
    :persistent="persistent"
    :model-name="modelClassName"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="12">
          <company-select :value="companyId" @change="onSelectCompany" />
        </v-col>
        <v-col cols="4">
          <v-switch
            :label="$t('include.canceled')"
            :value="1"
            v-model="obFilterData.canceled"
            @change="apply"
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            :label="$t('include.completed')"
            :value="1"
            v-model="obFilterData.completed"
            @change="apply"
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            :label="$t('include.failed')"
            :value="1"
            v-model="obFilterData.failed"
            @change="apply"
          />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";

import SheetFilters from "@/components/form/SheetFilters.vue";
import { AppModule } from "@/store/app";
import ReportJobMixin from "../mixins/ReportJobMixin";
import { Company } from "@planetadeleste/vue-mc-gw";
import CompanySelect from "@/modules/companies/components/CompanySelect.vue";

@Component({
  components: {
    SheetFilters,
    CompanySelect,
  },
})
export default class ReportJobFilters extends Mixins(ReportJobMixin) {
  @Prop(Boolean) readonly persistent!: boolean;
  obFilterData: Record<string, any> = {};
  obCompany: Company = new Company();

  get companyId(): number | undefined {
    return this.obCompany ? this.obCompany.id : undefined;
  }
  onSelectCompany(obData: Company) {
    this.obCompany = obData;
  }
  onMounted() {
    this.obFilterData = this.$_.get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    console.log("eze", this.obFilterData);

    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");
    this.applyFilters();
  }
}
</script>
